/* eslint-disable @nx/enforce-module-boundaries */
import { Box, HStack, Stack } from '@mybridge/ui/layout';
import { Link } from '@mybridge/ui/link';
import { ReplyCountIcon } from '@mybridge/icons/comments';
import { Text } from '@mybridge/ui/text';
import { useContext, useState } from 'react';
import { PostCommentBox } from './comment';
import { Divider } from '@mybridge/ui/divider';
import { PostBoxContext } from 'v4/components/post/context';

export const PostCommentRows = ({
  post,
  comments,
  commentBoxVisible=false,
  showCommentsDefault = false,
  level = 0,
  commentBoxExpanded,
  setCommentBoxExapnded
}) => {


  const [showComments, setShowComments] = useState(showCommentsDefault);
  const {
    post: thisPost,
    refetch,
    refetchComments,
  } = useContext(PostBoxContext);
  const { comment_count } = thisPost ?? {};
  const handleShowComments = () => {
    setShowComments(!showComments);
  };

  const showExpander = level > 0 ? !showComments : true;
  
  return (
    <>
      {comments?.length > 0 ? (
        <>
          <Stack pb={4} spacing={0}>
            {showExpander ? (
              <HStack mt={1} pos="relative">
                {level > 0 ? (
                  <Box
                    borderBottom="2px solid"
                    borderLeft="2px solid"
                    borderColor="gray.300"
                    pos="absolute"
                    top={commentBoxExpanded ? -40 : commentBoxVisible ? -9 : 0}
                    h={commentBoxExpanded? "180px" : commentBoxVisible ? "55px" : 6}
                    left={-9}
                    w="7"
                    borderBottomLeftRadius={10}
                  />
                ) : (
                  ''
                )}
                <Box
                  borderRight="1px solid"
                  borderColor="gray.200"
                  pos="absolute"
                  top={-16}
                  // h={10}
                  left={-9}
                  w="1px"
                />
                <HStack>
                  {level > 0 ? <ReplyCountIcon /> : ''}
                  <Link
                    href="javascript:void(0);"
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onClick={level > 0 ? handleShowComments : () => {}}
                  >
                    <Text pr={4} py={2} fontSize="lg" fontWeight="medium">
                      {level > 0 ? comments?.length : comment_count} Comment
                      {comments?.length > 1 ? 's' : ''}
                    </Text>
                  </Link>
                </HStack>
              </HStack>
            ) : (
              ''
            )}

            {showComments &&
              comments?.map?.((comment, cindex) => (
                <Stack key={cindex} spacing={0}>
                  <PostCommentBox
                    isLast={cindex >= comments.length - 1}
                    level={level + 1}
                    post={post}
                    key={cindex}
                    comment={comment}
                    commentList={comments}
                    refetch={() => {
                      refetchComments?.();
                      refetch?.();
                    }}
                    commentBoxExpanded={commentBoxExpanded}
                    setCommentBoxExapnded={setCommentBoxExapnded}
                  />
                </Stack>
              ))}
          </Stack>
        </>
      ) : (
        ''
      )}
    </>
  );
};
