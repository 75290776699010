/* eslint-disable @nx/enforce-module-boundaries */
import { Divider } from '@mybridge/ui/divider';
import { Box, Stack } from '@mybridge/ui/layout';
import { useState } from 'react';
import { PostCommentRowBody } from 'v4/components/comment/comment-row/body';
import {
  PostCommentRowContext,
  usePostCommentRowContext,
} from 'v4/components/comment/comment-row/context';
import { PostCommentRowFooter } from 'v4/components/comment/comment-row/footer';
import { PostCommentRowFooterActions } from 'v4/components/comment/comment-row/footer-actions';
import { PostCommentRowHeader } from 'v4/components/comment/comment-row/header';
import { PostCommentRowInsightPlate } from 'v4/components/comment/comment-row/insight-plate';
import { PostCommentRows } from '..';
import { PostCommentForm } from '../../comment-form';
import { PostCommentEditForm } from '../edit-comment-form';
import { PostCommentRowDeleteModal } from 'v4/components/comment/comment-row/comment-actions-modals/delete-comment';
import { Text } from '@mybridge/ui';

export const PostCommentBox = ({
  comment,
  post,
  refetch,
  level,
  isLast = false,
  commentList
  }) => {
  const [commentBoxVisible, setCommentBoxVisible] = useState(false);

  const postCommentRowContext = usePostCommentRowContext({
    post,
    comment,
    refetch,
    level,
    commentList:commentList
  });


  const {
    comment: stateComment,
    mode,
    isMyComment,
    deleteCommentModal,
    setCommentBoxExapnded,
    commentBoxExpanded
  } = postCommentRowContext ?? {};


  // const { expanded } = postCommentFormContext ?? {};


  const { comment_replies, removed, owner } = stateComment ?? {};


  if (removed) return <></>;



  return (
    <Stack spacing={0} pos="relative">
      <PostCommentRowContext.Provider
        value={{
          ...(postCommentRowContext ?? {}),
          commentBoxVisible,
          setCommentBoxVisible,
        }}
      >

        {level > 1 && !isLast ? (
          <>
            <Box
              borderRight="2px solid"
              borderColor="gray.300"
              pos="absolute"
              w="0px"
              // top={0}
              top={-4}
              {...(isLast ? { h: 16 } : { bottom: -8 })}
              left={-9}
            />
          </>
        ) : (
          ''
        )}
        <Stack pos="relative" spacing={0}>
          {mode === 'view' ? (
            <>
              <Stack spacing={0} pos="relative">
                {level > 1 ? (
                  <Box
                    borderBottom="2px solid"
                    borderLeft="2px solid"
                    borderColor="gray.300"
                    pos="absolute"
                    top={'-10rem'}
                    h={'12.5rem'}
                    left={-9}
                    w="7"
                    borderBottomLeftRadius={10}
                  />
                ) : (
                  ''
                )}
                {comment_replies?.length || (level>1 && commentBoxVisible) || (level==1 && commentBoxVisible) ? (
                  <>
                    <Box
                      borderRight="2px solid"
                      borderColor="gray.300"
                      pos="absolute"
                      w="0px"
                      // top={0}
                      top={"70px"}
                      // {...(isLast ? { h: 20 } : { bottom: 0 })}
                      h={comment_replies?.length ? "180px" : "145px"}
                      bottom={level==1 && comment_replies?.length  ? -28 : -50}
                      left={5}
                    />
                  </>
                ) : (
                  ''
                )}

                {/* <Box
                  borderLeft="1px solid"
                  borderBottom="1px solid"
                  borderColor="gray.200"
                  borderBottomLeftRadius={10}
                  pos="absolute"
                  top="20px"
                  h="20px"
                  left={-9}
                  w="7"
                /> */}
                <PostCommentRowHeader showActions={isMyComment} />
                <Box pl={'14'}>
                  <Box
                    bg="#EFF3F5"
                    w="fit-content"
                    borderRadius="10"
                    overflow="hidden"
                    mb={2}
                    ml={-1}
                  >
                    <PostCommentRowBody commentOwner={owner} />
                  </Box>
                </Box>
              </Stack>
              <Box pl={'14'}>
                <PostCommentRowFooter>
                  <PostCommentRowInsightPlate />
                  <PostCommentRowFooterActions />
                  {commentBoxVisible ? (
                    <Stack spacing={0} pos="relative">
                      <Box
                        borderBottom="2px solid"
                        borderLeft="2px solid"
                        borderColor="gray.300"
                        pos="absolute"
                        top={comment_replies?.length  ? -28 : 0}
                        // top={0}
                        // h={8}
                        left={-9}
                        h={comment_replies?.length  ? "140px" : 8}
                        w="7"
                        borderBottomLeftRadius={10}
                      />
                      {/* <Box
                        borderRight="1px solid"
                        borderColor="gray.300"
                        pos="absolute"
                        w="0px"
                        top={-135}
                        // top={0}
                        bottom={30}
                        left={-9}
                      /> */}
                      <PostCommentForm pl={0} />
                    </Stack>
                  ) : (
                    ''
                  )}
                </PostCommentRowFooter>
              </Box>
            </>
          ) : (
            ''
          )}
        </Stack>

        {/* Edit comment form */}
        {mode === 'edit' ? (
          <>
            <PostCommentEditForm pl={0} borderTop="none" />
          </>
        ) : (
          ''
        )}
        {/* Remove comment modal */}
        {deleteCommentModal?.isOpen ? (
          <PostCommentRowDeleteModal {...deleteCommentModal} />
        ) : (
          ''
        )}
      </PostCommentRowContext.Provider>

      {comment_replies?.length ? (
        <Box pl={14}>
          <Divider opacity={1} borderColor="gray.200" />
          <PostCommentRows
            level={level}
            refetch={refetch}
            post={post}
            commentBoxVisible={commentBoxVisible}
            setCommentBoxExapnded={setCommentBoxExapnded}
            commentBoxExpanded={commentBoxExpanded}
            comments={comment_replies}
          />
        </Box>
      ) : (
        ''
      )}
    </Stack>
  );
};
